<template>
	<b-overlay
		:show="isLoading"
		rounded="lg"
		opacity="0.6"
		spinner-variant="primary"
	>
		<template #overlay>
			<div class="d-flex align-items-center">
				<b-spinner small type="grow" variant="secondary"></b-spinner>
				<b-spinner type="grow" variant="dark"></b-spinner>
				<b-spinner small type="grow" variant="secondary"></b-spinner>
			</div>
		</template>
		<b-row class="mt-4 create-new">
			<b-col cols="12">
				<router-link class="btn btn-sm btn-default" :to="{name: RoleManagePath.name}">
					<em class="fa fa-backward"></em> Manage Roles
				</router-link>
			</b-col>
			<b-col>
        <b-form @submit='onSubmit' @reset='onReset'>
					<div class="card mt-4">
						<div class="bg-success p-3">
							<h5 class="card-title mb-0 font-weight-bold">
								Create New Role
							</h5>
						</div>
						<div class="card-body">
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-card class="bg-info text-dark">(*) Kolom berlabel bintang wajib diisi.</b-card>
								</b-col>
							</b-row>
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-form-group label="Name *">
										<b-form-input
											id="name"
											v-model="form.name"
											type="text"
											step="any"
											placeholder="input name"
											required
										>
										</b-form-input>
									</b-form-group>
								</b-col>
							</b-row>
							<br />
							<b-row>
								<b-col>
									<b-button variant="primary" type="submit">create</b-button>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-form>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { RoleManagePath } from "../../router/account";

export default {
	name: "newRole",
	data() {
		return {
			RoleManagePath,
			debounce: null,
			form: {
				name: null,
			},
		};
	},
	computed: {
		...mapState({
			isError: (state) => state.accessRole.isError,
			isLoading: (state) => state.accessRole.isLoading,
			errorMessage: (state) => state.accessRole.errorMessage,
			successMessage: (state) => state.accessRole.successMessage,
			item: (state) => state.accessRole.item
		})
	},
	watch: {
		errorMessage: function() {
			if (!this.errorMessage) return;
			this.messageAlert("error", this.errorMessage, 5000);
		},
		successMessage: function() {
			if (!this.successMessage) return;
			this.messageAlert("success", this.successMessage);

			Object.assign(this.$data, this.$options.data.call(this));
			this.$router.push(RoleManagePath);
		}
	},
	created() {},
	methods: {
		...mapActions("accessRole", ["postRole"]),
		onSubmit(event) {
			event.preventDefault();
			const data = this.form;
			const payload = {
				name: data.name,
			};
			this.postRole(payload);
		},
		onReset() {},
		messageAlert(icon, title, timer = 3000) {
			this.$swal({
				toast: "true",
				position: "top-end",
				icon,
				title,
				showConfirmButton: false,
				timer,
				timerProgressBar: true,
			});
		},
	},
};
</script>
